import useLanguage from '~/contexts/language/useLanguage'
import { logEvent } from '~/lib/events'

const trackAppStoreLink = () => logEvent('Followed App Store Link')
const trackGooglePlayLink = () => logEvent('Followed Google Play Link')

const appStoreLink =
  'https://itunes.apple.com/us/app/heja-simple-communication/id1157335714?pt=119072841&ct=download-page&mt=8'
const googlePlayLink =
  'https://play.google.com/store/apps/details?id=com.heja.app&utm_source=heja.io&utm_medium=web&utm_campaign=download-page'

export default function DownloadButtons() {
  const lang = useLanguage()
  return (
    <div className="flex flex-row gap-2 items-center -ml-2">
      <a href={appStoreLink} onClick={trackAppStoreLink} className="link">
        <img
          src={`/images/${lang}/download-app-store.svg`}
          width={175.5}
          height={52}
          alt="Download on App Store"
          style={{ width: '175.5px', maxWidth: 'none', height: '52px' }}
        />
      </a>
      <a href={googlePlayLink} onClick={trackGooglePlayLink} className="link">
        <img
          src={`/images/${lang}/google-play-badge.png`}
          width={175.5}
          height={52}
          style={{ maxWidth: 'none' }}
          alt="Download on Google Play"
        />
      </a>
    </div>
  )
}
